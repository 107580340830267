import Image from 'next/image';
import { ReactNode } from 'react';

import { FullScreenMenu } from '@components/FullScreenMenu';
import { useCategoriesSidebarVisibilityState } from '@hooks/useCategoriesSidebarVisibilityState';
import { useIsMediaBreakpoint } from '@hooks/useIsMediaBreakpoint';
import { invoiceStateByEntity } from '@packages/messaging-and-invoicing/constants';
import { useMessagingAndInvoicing } from '@packages/messaging-and-invoicing/MessagingAndInvoicing/hooks';
import { useInvoices } from '@packages/messaging-and-invoicing/useInvoices';
import { useMyConversations } from '@packages/messaging-and-invoicing/useMyConversations';

type SidebarLayoutProps = {
  children: ReactNode;
};

export const SidebarLayout = ({ children }: SidebarLayoutProps) => {
  const { setIsCategoriesSidebarVisible } = useCategoriesSidebarVisibilityState();

  const isTablet = useIsMediaBreakpoint('md');
  const isDesktop = useIsMediaBreakpoint('xl');

  const messagingAndInvoicing = useMessagingAndInvoicing();
  const openMessaging = () => messagingAndInvoicing.toggleMessaging();
  const openInvoicing = () => messagingAndInvoicing.toggleInvoicing();
  const openFunder = () => window.open('https://www.wtenth.com/invest', '_blank');

  const { data: invoices } = useInvoices();
  const invoicesToBePaid = invoices?.filter(
    (invoice) => invoice.status && invoiceStateByEntity[invoice.status] === 'PENDING'
  );

  const { data: conversations = [] } = useMyConversations();
  const unreadMessages = conversations.filter((c) => c.unReadMessageCountByUser > 0);

  return (
    <>
      <div className="sidebar-layout-menu d-none d-md-block">
        <div
          className="menu-button position-fixed p-3"
          onClick={() => setIsCategoriesSidebarVisible(true)}
        >
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </div>
      </div>

      <div
        style={{
          marginLeft: isTablet ? '85px' : '0',
          paddingTop: isDesktop ? '6rem' : isTablet ? '8.4rem' : '9rem',
        }}
        className={`sidebar-layout-content overflow-hidden position-relative ${
          isTablet ? 'border-start' : ''
        }`}
      >
        <div>
          <div
            onClick={openFunder}
            className="cursor-pointer text-center text-primary py-1 fw-bold"
            style={{ backgroundColor: '#FFA0DC' }}
          >
            <Image
              src={'/images/fundraisestars.png'}
              width={28}
              height={16}
              alt={'fundraise stars'}
            />
            Love West Tenth? Invest in our Community Round
            <Image
              src={'/images/fundraisestars.png'}
              width={28}
              height={16}
              alt={'fundraise stars'}
            />
          </div>

          {invoicesToBePaid?.length ? (
            <div
              onClick={openInvoicing}
              className="cursor-pointer text-primary text-center py-1"
              style={{ backgroundColor: '#BFCEF9' }}
            >
              You have {invoicesToBePaid.length} unpaid orders
            </div>
          ) : null}

          {unreadMessages.length > 0 ? (
            <div
              onClick={openMessaging}
              className="cursor-pointer text-center text-primary py-1"
              style={{ backgroundColor: '#FFD1CE' }}
            >
              You have {unreadMessages.length} unread messages
            </div>
          ) : null}
        </div>
        {children}
      </div>

      <FullScreenMenu />
    </>
  );
};
